import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Kiji from "@components/kiji-link";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <section className='honbun'>
      <p><strong parentName="p">{`補足：`}</strong></p>
      <p>{`この場合の「ゲインで補う」というのは、「コンプによって圧縮されて小さくなってしまった音量を、圧縮される前と同じ大きさになるように、ゲインで補う」と言う作業です。ですから、コンプをかける前…もっと厳密に言うと「リファレンスのボーカル」とほぼ同じ大きさを保つように、ゲインを設定します。`}<br parentName="p"></br>{`
`}{`そうしないと、最初に楽器同士の「大きさ」を揃えた意味がなくなってしまいます。`}</p>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      