import React from 'react'
import { Link } from "gatsby"
import Helmet from 'react-helmet'

// import firebase from 'firebase/app'
// import 'firebase/firestore'
import lazysizes from 'lazysizes'
import './global.css'

import BN from '../components/elements/back-next'
import Menu	from '../components/elements/menu'
import HakusiFooter from '../components/hakusi-footer'




export default (props) => {

// LocalStorageを読み込む
	if (typeof document !== `undefined`) {
		var nakami = localStorage.lk
		var email = localStorage.email
		var seimei = localStorage.seimei
		// alert(email+seimei) // debug
	}

	// LocalStorageに、キーや個人情報が入ってない時
if (!nakami || !email || !seimei){
	// リダイレクトする
	if (typeof document !== `undefined`) {
		// alert('naikara redirecting!') //debug
		window.location.replace("/elements/form/");
	}
}
// LocalStorageのにキーが入っている時
else {


// 	// キーがまだ正しいか、データベースと照合する
// 		if (!firebase.apps.length) {	//Init Firestore
// 		let config = {
// 			apiKey: "AIzaSyBNCGNyBwbfMAaFyqn-IWMp-Zsum5Tnsqw",
// 			authDomain: "elvn-project.firebaseapp.com",
// 			databaseURL: "https://elvn-project.firebaseio.com",
// 			projectId: "elvn-project",
// 			storageBucket: "elvn-project.appspot.com",
// 			messagingSenderId: "1087876269075"
// 		};
// 		// Init Firebase
// 		firebase.initializeApp(config);
// 		}

// 		// データベースと照合する
// 		console.log('syougou start!') // debug
// 	const db = firebase.firestore();
// 	let docRef = db.collection("license").doc(nakami);
// 	docRef.get().then(function(doc) {

// 		// 合致するデータがあった時
// 		if (doc.exists) {

// 				console.log("attayo! :", doc.data()); // debug
				//※支払いのチェックを追加する

// 			}
// 		// 合致するデータがなかった時
// 		else {
	// 			// リダイレクトする
	// 				alert('naikara redirect!') // debug
// 			 if (typeof document !== `undefined`) {
// 				window.location.replace("../form/");
// 			 }
// 		}
// 	})
// 	.catch(function(error) {
// 		alert("エラー：", error)
// 	})

}



return(

<>

<Helmet

    meta={[
      {
        name: `robots`,
        content: `noindex noarchive`,
      }
    ]}

  script={[{
   type: 'text/javascript',
   innerHTML: `


    `
  }]} >

	<noscript>
		{`<meta http-equiv="Refresh" content="0; URL=https://elvn.tokyo/osirase/noscript/"/>`}
	</noscript>

</Helmet>

		<div class='t-center kuro_bar'>おかえりなさい、{seimei} さん （ {email} ）</div>

<main class="b-center seigen">

	<h1>{props.h1}</h1>

	<div class="resp_video kage" style={{ display: props.douga_vi }}>
		<iframe class="lazyload" src={'https://player.vimeo.com/video/' + props.url + '?playsinline=0'} frameborder="0" allow="fullscreen; autoplay; encrypted-media; picture-in-picture"></iframe>
	</div>

    {props.children}


	<BN
	 back={props.mae}
	 next={props.ato}
	 mae_vi={props.mae_vi}
	 ato_vi={props.ato_vi}
	/>

<Menu/>


</main>


<HakusiFooter/>

</>


)
}