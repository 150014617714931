import React from "react"
import { Link } from "gatsby"

export default (props) => {

	return (

<div className="menu_elements">
	<ul>
	<li><Link to="/elements/start/">ELEMENTSの使い方</Link></li>
	</ul>

	【０】大前提
	<ol>
	<li><Link to="/elements/0/0-1/">５つの手順</Link></li>
	<li><Link to="/elements/0/0-2/">７５点の法則</Link></li>
	<li><Link to="/elements/0/0-3/">StudioOneを使う理由</Link></li>
	<li><Link to="/elements/0/0-4/">基本操作</Link></li>
	</ol>

	【１】大きさ
	<ol>
	<li><Link to="/elements/1/1-1/">「大きさ」２つの作業</Link></li>
	<li><Link to="/elements/1/1-2/">お手本が絶対に必要な理由</Link></li>
	<li><Link to="/elements/1/1-3/">リファレンスの立て方</Link></li>
	<li><Link to="/elements/1/1-4/">リファレンス選びの４ポイント</Link></li>
	<li><Link to="/elements/1/1-5/">作業の仕方</Link></li>
	</ol>

	【２】配置
	<ol>
	<li><Link to="/elements/2/2-1/">配置とは？守るべき注意</Link></li>
	<li><Link to="/elements/2/2-2/">低域と高域の配置</Link></li>
	<li><Link to="/elements/2/2-3/">中域の配置</Link></li>
	<li><Link to="/elements/2/2-4/">準主役と脇役の見分け方</Link></li>
	<li><Link to="/elements/2/2-5/">配置の原則</Link></li>
	</ol>

	【３】強弱
	<ol>
	<li><Link to="/elements/3/3-1/">コンプレッサーとは</Link></li>
	<li><Link to="/elements/3/3-2/">メーターだけでコンプを設定する方法</Link></li>
	<li><Link to="/elements/3/3-3/">ボーカルにコンプをかけてみよう</Link></li>
	<li><Link to="/elements/3/3-4/">レシオ</Link></li>
	<li><Link to="/elements/3/3-5/">レシオはどれぐらいに設定すればいいの？</Link></li>
	<li><Link to="/elements/3/3-6/">レシオは必要なのか？</Link></li>
	<li><Link to="/elements/3/3-7/">コンプで迫力が出るのはなぜ？</Link></li>
	<li><Link to="/elements/3/3-8/">アタックタイム</Link></li>
	<li><Link to="/elements/3/3-9/">リリースタイム</Link></li>
	<li><Link to="/elements/3/3-10/">音圧が低い、とは？</Link></li>
	<li><Link to="/elements/3/3-11/">音圧を測ろう</Link></li>
	<li><Link to="/elements/3/3-12/">ポストフェーダー</Link></li>
	<li><Link to="/elements/3/3-13/">リミッター</Link></li>
	<li><Link to="/elements/3/3-14/">リミッターだけで音圧を上げてはいけないのは、なぜ？</Link></li>
	<li><Link to="/elements/3/3-15/">抑揚を殺さずにラウドネスを上げる方法</Link></li>
	<li><Link to="/elements/3/3-16/">なぜマスターコンプでボーカルが伴奏に馴染むのか？</Link></li>
	</ol>

	【４】高さ別の大きさ
	<ol>
	<li><Link to="/elements/4/4-1/">ヘルツとイコライザー</Link></li>
	<li><Link to="/elements/4/4-2/">EQの操作と九九</Link></li>
	<li><Link to="/elements/4/4-3/">鼓動の60</Link></li>
	<li><Link to="/elements/4/4-4/">土台の80</Link></li>
	<li><Link to="/elements/4/4-5/">肉厚200</Link></li>
	<li><Link to="/elements/4/4-6/">低域連続再生</Link></li>
	<li><Link to="/elements/4/4-7/">《九九》低域</Link></li>
	<li><Link to="/elements/4/4-8/">丸みの700</Link></li>
	<li><Link to="/elements/4/4-9/">芯1500</Link></li>
	<li><Link to="/elements/4/4-10/">エッジの5k</Link></li>
	<li><Link to="/elements/4/4-11/">中域連続再生</Link></li>
	<li><Link to="/elements/4/4-12/">《九九》中域</Link></li>
	<li><Link to="/elements/4/4-13/">サラサラ8k</Link></li>
	<li><Link to="/elements/4/4-14/">シャラシャラ10k</Link></li>
	<li><Link to="/elements/4/4-15/">高域連続再生</Link></li>
	<li><Link to="/elements/4/4-16/">《九九》高域</Link></li>
	<li><Link to="/elements/4/4-17/">一瞬で自然なEQを仕上げる方法</Link></li>
	<li><Link to="/elements/4/4-18/">どこをEQすればいいか</Link></li>
	<li><Link to="/elements/4/4-19/">マスターEQで音作りしてはいけない理由</Link></li>
	<li><Link to="/elements/4/4-20/">マスターEQは何倍も極端にかかってしまう！</Link></li>
	</ol>

	【５】エコー
	<ol>
	<li><Link to="/elements/5/5-1/">リバーブとエコー</Link></li>
	<li><Link to="/elements/5/5-2/">インサートで使ってはいけない理由</Link></li>
	<li><Link to="/elements/5/5-3/">必ず守ること</Link></li>
	<li><Link to="/elements/5/5-4/">リバーブを超速で仕上げる手順</Link></li>
	<li><Link to="/elements/5/5-5/">リバーブのパラメーター</Link></li>
			</ol>

			特典
	<ol>
	<li><a href="https://drive.google.com/uc?id=1qrUQrZYnbdhBOHccQuFazCoBGc4pK7qA" target='_blank' rel="noopener noreferrer">要点表</a></li>
	<li><a href="https://drive.google.com/uc?id=1ucHoiTocxJmpUo7y5Gubce82h6HDGkzs" target='_blank' rel="noopener noreferrer">用語集</a></li>

			</ol>

	【聞き流し用】連続再生
	<ol>
	<li><Link to="/elements/6/6-1/">連続再生「大前提」</Link></li>
	<li><Link to="/elements/6/6-2/">連続再生「大きさ」</Link></li>
	<li><Link to="/elements/6/6-3/">連続再生「配置」</Link></li>
	<li><Link to="/elements/6/6-4/">連続再生「強弱」</Link></li>
	<li><Link to="/elements/6/6-5/">連続再生「高さ別の大きさ」</Link></li>
	<li><Link to="/elements/6/6-6/">連続再生「エコー」</Link></li>
	</ol>

	<ul>
	<li><Link to="/elements/kiyaku/">- 利用規約 -</Link></li>
	</ul>
</div>


	)

}