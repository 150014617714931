import React from "react"
import { Link } from "gatsby"

export default (props) => (


<div className="back_next">
	<Link to={props.back} style={{ visibility: props.mae_vi }} class='back'>前へ</Link>
	<Link to={props.next} style={{ visibility: props.ato_vi }} class='next'>次へ</Link>
</div>


)